<template>
  <div></div>
</template>

<script>
import { Toast } from 'vant'
import { getUserInfo,getWaterMarkTxt,getV8SsoInfo } from '@/api/wx.js'
import { removeWatermark, setWaterMark } from '@/utils/watermark'
export default {
  data() {
    return {}
  },
  mounted() {
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 0
    })
    localStorage.setItem('url', window.V3url + '/weixinlocal/index.html')
    sessionStorage.setItem('url', window.V3url + '/weixinlocal/index.html')
    this._getV8SsoInfo()
  },
  methods: {
    // 获取用户信息
    _getV8SsoInfo() {
      getV8SsoInfo({  
        username: this.$route.query.username,
        key: this.$route.query.key,
        langid: this.$route.query.langid,
        code: this.$route.query.code,
        state: this.$route.query.state,
        eipaulg: this.$route.query.eipaulg,
        querystring: JSON.stringify(this.$route.query) }).
        then(res => {
        localStorage.setItem('userInfo', JSON.stringify(res.data[0]))
        sessionStorage.setItem('userInfo', JSON.stringify(res.data[0]))
        localStorage.setItem('v8_login_pwd', res.data[0].pwd)
        localStorage.setItem('v8_login_username', res.data[0].username)
        getWaterMarkTxt({
          username:res.data[0].username
        }).then(res=>{
          const ressy = res.data;
          if(ressy && ressy.length > 0){
            const syobj = ressy[0];
            if(syobj.show_watermark == 1){
              localStorage.setItem("sysShuiYin",JSON.stringify(syobj))
              setWaterMark(
                syobj.watermark_line1,
                syobj.watermark_line2,
                syobj.watermark_line3
              )
            }else{
              localStorage.removeItem("sysShuiYin")
            }
          }
        })
        //单点登录后跳转旧版界面用
        try {
          SetUserLocal(
            window.CloundEntid,
            window.CloundEntid,
            res.data[0].empcode,
            res.data[0].empname,
            res.data[0].username,
            res.data[0].v8_login_pwd,
            res.data[0].empname,
            0,
            res.data[0].empid
          )
          setLocVal('spusername', res.data[0].username)
        } catch (error) {}

        Toast.clear()
        this.goto()
      })
    },
    // 跳转
    goto() {
      /*
      http://v8h5.hyclound.cn/AscLogin?usertoken=C6478614104D1178FAD45E54E24D3E80&path=/weixinlocal/queryrpt/yidongqiandaofanwei.html?yyy=1
				parmams格式【参数名称1_参数值1@参数名称2_参数值2】:empid_19%khplanid_12........
      */
      let past = this.$route.query.path // 跳转路由地址
      if (this.$route.query.params) {
        // 传入参数
        const params = this.$route.query.params
        let arr = params.indexOf('@') > -1 ? params.split('@') : [params]
        arr.forEach((e, i) => {
          const dl = i === 0 ? '?' : '&'
          const a = e.split('_')
          past += dl + a[0] + '=' + a[1]
        })
      }
      Toast.clear()
      if (this.$route.query.path.indexOf('/') == 0) {
        //旧版界面必须以/开头
        window.location.href = past
      } else {
        this.$router.replace('/' + past)
      }
    }
  }
}
</script>

<style></style>
